jQuery(document).ready(function ($) {
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
    jQuery('.col-stats').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
    jQuery('.review_content').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });
    jQuery('.review_text').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });  

    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });

    // MOBILE MENU TOGGLE
    function animateMenu(element, action) {
        var $this = element;
        
        if(action == '') {
            jQuery("#side_menu_wrapper").toggleClass('open');
            if(!$this.hasClass("closed")) {

                jQuery(jQuery("#mobile-menu > li").get().reverse()).each(function(i) { 
                    TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
                });

                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '0%'});
                
                jQuery('#hamburger-icon-close').addClass("closed");
                jQuery("#hamburger-icon").addClass("closed");
                jQuery("#hamburger-icon-sticky").addClass("closed");
                jQuery("body").removeClass("menu-open");
            } else {

                jQuery("#mobile-menu > li").each(function(i) {
                    TweenLite.to(jQuery(this), 0.2 * i, {ease: Power4.easeInOut, opacity:1});
                });

                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '-100%'});
                
                jQuery("#hamburger-icon-close").removeClass("closed");
                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("#hamburger-icon-sticky").removeClass("closed");
                jQuery("body").addClass("menu-open");
            }
        } else if(action == 'close') {
            jQuery("#side_menu_wrapper").removeClass('open');
            jQuery(jQuery("#mobile-menu > li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
            });

            TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '0%'});
            
            jQuery('#hamburger-icon-close').addClass("closed");
            jQuery("#hamburger-icon").addClass("closed");
            jQuery("#hamburger-icon-sticky").addClass("closed");
            jQuery("body").removeClass("menu-open");
        }
    }

    jQuery('#hamburger-icon-close').click(function() {
        animateMenu(jQuery(this), '');
    });
    
    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery(".main").click(function() {
        animateMenu(jQuery(this), 'close');
    });

    jQuery('#mobile-menu a').click(function(e) {
        animateMenu(jQuery(this), '');
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });
});